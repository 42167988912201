/**
 * This module initialises the interface for the login page
 */

 import events from './common/events'

 
 const init = () => {
   /**
    * Events listener
    */
    $('.loading').hide()
   events.initEvents()
 }
 
 /**
  * RUN THE APP
  */
 
 init()